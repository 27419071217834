import React, { useEffect, useState } from 'react'
import cattle1 from "../../../assets/images/cattle.png"
import {
  dateIcon,
  locationIcon,
  timeIcon,
  totalTaskIcon,
} from "../../../assets/Table/TabListIcon";
import {
  correctIcon,
  rejectIcon,
  rightArrowIcon,
  reportNowIcon,
} from "../../../assets/Table/TableActionIcon";
import { getOrderDetails } from '../../../services/orders';
import { useQuery } from '@tanstack/react-query';
import { api_keys } from '../../../config/constants';
import { config } from '../../../config/config';
import { toast, ToastContainer } from 'react-toastify';
import HeaderSection from '../../../components/HeaderSection';
import { Loader } from '../../../components/Loader';
import { axios } from '../../../config/axios';

function ReporterDetailPage({ changeDetailPage, activeTab, ItemDetail }) {

  const [showImages, setShowImages] = useState(
    {
      task_completed_image:null, 
      image1: null,
      image2: null,
      image3: null,
      }
  );


  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [imageFileBefore, setImageFileBefore] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [imageFileBefore2, setImageFileBefore2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [imageFileBefore3, setImageFileBefore3] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setImageFile(file);
    }
  };

  const handleImageChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage1(imageUrl);
      setImageFileBefore(file);
    }
  };
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage2(imageUrl);
      setImageFileBefore2(file);
    }
  };
  const handleImageChange3 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage3(imageUrl);
      setImageFileBefore3(file);
    }
  };


  const handleSubmit = async () => {
    setLoading(true)
    console.log(ItemDetail.id)
    if (imageFile) {
      const task = ItemDetail?.SlaughterTasks?.find(task => task.status === 'PARTIAL_COMPLETED');
      const formData = new FormData();
      formData.append('task_id', task.id); // Assuming ItemDetail is defined elsewhere
      formData.append('task_completed_image', imageFile);
      formData.append('image1', imageFileBefore);
      formData.append('image2', imageFileBefore2);
      formData.append('image3', imageFileBefore3);


      const token = JSON.parse(localStorage.getItem("token"));

      try {
        const response = await axios.patch(`executor/reporter-upload-completed-task`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`, // Add the token to the request headers
          },
        });
        toast.success("Images Uploaded Successfully")

        setLoading(false)
      } catch (error) {
        console.error(error);
        toast.error(error?.message)
        setLoading(false)

      }
    }
  };


  useEffect(()=>{
    if (activeTab === "PARTIAL_COMPLETED" || activeTab === "COMPLETED") {
        ItemDetail?.SlaughterTasks?.some((task) => {
        if (task.status === "PARTIAL_COMPLETED" || task.status === "COMPLETED") {
         
          setShowImages({
            task_completed_image:task?.task_completed_image, 
            image1: task?.image1,
            image2: task?.image2,
            image3: task?.image3,
            })
        
        }
       
      });
    }
  },[])


  return (
    <div>
      <button className="px-4 py-5 text-[#00ADB9]" onClick={() => changeDetailPage()}>Return</button>





      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">

      {(activeTab === "PARTIAL_COMPLETED" || activeTab === "COMPLETED") && (
  showImages.task_completed_image && (
    <div className=' p-9'>
      <h1 className='mb-4'>Uploaded Images</h1>
    <div className='flex'>
      <img  src={`${config?.backendBaseURL}uploads/${showImages?.image1}`} className='w-16 mr-3 mb-3'/>
      <img  src={`${config?.backendBaseURL}uploads/${showImages?.image2}`} className='w-16 mr-3 mb-3'/>

      <img  src={`${config?.backendBaseURL}uploads/${showImages?.image3}`} className='w-16 mr-3 mb-3'/>
      <img  src={`${config?.backendBaseURL}uploads/${showImages?.task_completed_image}`} className='w-16 mr-3 mb-3'/>

      </div>
      </div>
  )
)}



        {activeTab == "PARTIAL_COMPLETED" &&
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className={`text-xs  ${activeTab === "Rejected" ? "text-[#D9D9D9] " : " text-[#00ADB9]"} uppercase bg-gray-50 `}>
            <tr>
              <th scope="col" className="px-6 py-3" >
                Ruminant Image
              </th>

              <th scope="col" className="px-6 py-3">
                Action
              </th>

            </tr>
          </thead>
          <tbody>

            {activeTab == "PARTIAL_COMPLETED" ?
              <>
                <tr className="bg-white border-b  hover:bg-gray-50 ">
                  {selectedImage1 ? (

                    <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      <img src={selectedImage1} className="mr-2 w-[40px] h-[35px] rounded-md" />

                    </th>
                  ) : (
                    <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      <div className="mr-2 w-[40px] h-[35px] rounded-md bg-gray-500" > </div>


                    </th>
                  )}


                  <td className="px-6 py-4 text-white">
                    <button className='btn bg-[#9747FF]  p-2 rounded-md ' onClick={() => document.getElementById('imageUpload1').click()} >Upload Image 1</button>
                    <input type='file' id='imageUpload1' className='hidden' accept="image/*" onChange={handleImageChange1} />
                  </td>



                </tr>



                <tr className="bg-white border-b  hover:bg-gray-50 ">
                  {selectedImage3 ? (

                    <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      <img src={selectedImage3} className="mr-2 w-[40px] h-[35px] rounded-md" />

                    </th>
                  ) : (
                    <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      <div className="mr-2 w-[40px] h-[35px] rounded-md bg-gray-500" > </div>


                    </th>
                  )}


                  <td className="px-6 py-4 text-white">
                    <button className='btn bg-[#9747FF]  p-2 rounded-md ' onClick={() => document.getElementById('imageUpload3').click()} >Upload Image 2</button>
                    <input type='file' id='imageUpload3' className='hidden' accept="image/*" onChange={handleImageChange3} />
                  </td>



                </tr>

                <tr className="bg-white border-b  hover:bg-gray-50 ">
                  {selectedImage2 ? (

                    <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      <img src={selectedImage2} className="mr-2 w-[40px] h-[35px] rounded-md" />

                    </th>
                  ) : (
                    <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      <div className="mr-2 w-[40px] h-[35px] rounded-md bg-gray-500" > </div>


                    </th>
                  )}


                  <td className="px-6 py-4 text-white">
                    <button className='btn bg-[#9747FF] p-2 rounded-md ' onClick={() => document.getElementById('imageUpload2').click()} >Upload Image 3</button>
                    <input type='file' id='imageUpload2' className='hidden' accept="image/*" onChange={handleImageChange2} />
                  </td>



                </tr>

                <tr className="bg-white border-b  hover:bg-gray-50 ">
                  {selectedImage ? (

                    <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      <img src={selectedImage} className="mr-2 w-[40px] h-[35px] rounded-md" />

                    </th>
                  ) : (
                    <th scope="row" className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      <div className="mr-2 w-[40px] h-[35px] rounded-md bg-gray-500" > </div>


                    </th>
                  )}


                  <td className="px-6 py-4 text-white">
                    <button className='btn bg-[#9747FF] p-2 rounded-md ' onClick={() => document.getElementById('imageUpload').click()} >Upload Image 4</button>
                    <input type='file' id='imageUpload' className='hidden' accept="image/*" onChange={handleImageChange} />
                  </td>



                </tr>

                <tr className="bg-white border-b  hover:bg-gray-50 ">

                  <td className="px-6 py-4 text-white">
                  </td>

                  <td className="px-6 py-4 text-white">
                    {imageFile && imageFileBefore && imageFileBefore2 && imageFileBefore3 ? <>
                      {!loading ?
                        <button className='btn bg-green-500 p-2 rounded-md ' onClick={handleSubmit} >
                          Submit Images</button> :
                        <Loader />
                      }
                    </> :
                      <button className='btn bg-red-500 p-2 rounded-md ' disabled  >
                        Submit Images</button>


                    }
                  </td>



                </tr>


              </>
              :
              null

            }

          </tbody>
        </table>
}
      </div>

      <div className="overflow-auto max-h-80">













        <table className="min-w-full divide-y divide-gray-200">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">ID</th>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Image</th>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Allocated Portion</th>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Gender</th>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Package Type</th>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Feedlot Name</th>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Feedlot Id</th>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Supplier Name</th>
              <th scope="col" className="px-6 py-3 text-left text-base font-medium text-pacific-blue uppercase tracking-wider">Supplier Id</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {ItemDetail?.order?.animalStocks?.map((ruminant, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="px-6 py-4 whitespace-nowrap">{ruminant?.id}</td>
                <img src={`${config.backendBaseURL}${ruminant?.animal?.animal_image}`} className="h-12 w-12 rounded-full" alt="ruminant Image" />

                <td className="px-6 py-4 whitespace-nowrap">{ruminant?.portions}</td>
                <td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.animal_gender}</td>
                <td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.package_type}</td>
                <td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.feedlot?.fullname}</td>
                <td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.feedlot_id}</td>
                <td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.supplier?.fullname}</td>
                <td className="px-6 py-4 whitespace-nowrap">{ruminant?.animal?.supplier_id}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>


      {/* Principle Table */}

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className={`text-xs uppercase ${activeTab === "Rejected" ? "text-[#525252] bg-[#D9D9D9]" : "text-xs text-[#00ADB9] bg-[#CFF0F2]"} `}>
            <tr>
              <th scope="col" className="px-6 py-3">
                Id
              </th>
              <th scope="col" className="px-6 py-3">
                Customer Type
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>

              <th scope="col" className="px-6 py-3">
                Phone Number
              </th>
              <th scope="col" className="px-6 py-3">
                Country
              </th>
              <th scope="col" className="px-6 py-3">
                State
              </th>
              <th scope="col" className="px-6 py-3">
                Region
              </th>
              <th scope="col" className="px-6 py-3">
                IC Number
              </th>
              <th scope="col" className="px-6 py-3">
                Address
              </th>

              <th scope="col" className="px-6 py-3">
                Portion
              </th>
            </tr>
          </thead>
          <tbody>

            <tr className="bg-white border-b  hover:bg-gray-50 ">
              <td
                className="px-6 py-4">

                {ItemDetail?.order?.customer?.ref_id}
              </td>
              <td
                className="px-6 py-4">

                Principle
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.order?.customer?.fullname}
              </td>
              <td className="px-6 py-4">
                {ItemDetail?.order?.customer?.phone_no}
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.order?.customer?.country?.name}
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.order?.customer?.state?.name}
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.order?.customer?.region?.name}
              </td>



              <td className="px-6 py-4">
                {ItemDetail?.order?.customer?.ic_number}
              </td>
              <td className="px-6 py-4">
                {ItemDetail?.order?.customer?.address}
              </td>

              <td className="px-6 py-4">
                {ItemDetail?.order?.principle_portions}
              </td>

            </tr>







          </tbody>
        </table>


      </div>

      {/* Organization Table */}

      {ItemDetail?.order?.OrderHistoryOrganization?.length < 0 &&
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className={`text-xs uppercase ${activeTab === "Rejected" ? "text-[#525252] bg-[#D9D9D9]" : "text-xs text-[#00ADB9] bg-[#CFF0F2]"} `}>
              <tr>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>

                <th scope="col" className="px-6 py-3">
                  Portion
                </th>
              </tr>
            </thead>
            <tbody>







              {ItemDetail?.order?.OrderHistoryOrganization
                .map((organization, index) => (
                  <tr key={index} className="bg-white border-b">
                    <td className="px-6 py-4 whitespace-nowrap">{organization?.UserOrganization?.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">Organization</td>

                    <td className="px-6 py-4 whitespace-nowrap">{organization?.UserOrganization?.name}</td>


                    <td className="px-6 py-4 whitespace-nowrap">{organization?.portions}</td>

                  </tr>
                ))}

            </tbody>
          </table>


        </div>
      }



      {/* Family Table */}
      {ItemDetail?.order?.familyMembersPortions?.length < 0 &&
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 mb-5 border border-[#D9D9D9]">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className={`text-xs uppercase ${activeTab === "Rejected" ? "text-[#525252] bg-[#D9D9D9]" : "text-xs text-[#00ADB9] bg-[#CFF0F2]"} `}>
              <tr>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>


                <th scope="col" className="px-6 py-3">
                  IC Number
                </th>


                <th scope="col" className="px-6 py-3">
                  Portion
                </th>
              </tr>
            </thead>
            <tbody>



              {ItemDetail?.order?.familyMembersPortions.map((familyMember, index) => (
                <tr key={index} className="bg-white border-b">
                  <td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMemberId}</td>
                  <td className="px-6 py-4 whitespace-nowrap">Family</td>
                  <td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMember?.fullname}</td>

                  <td className="px-6 py-4 whitespace-nowrap">{familyMember?.familyMember?.ic_number}</td>


                  <td className="px-6 py-4 whitespace-nowrap">{familyMember?.portions}</td>

                </tr>
              ))}




            </tbody>
          </table>


        </div>
      }
      <ToastContainer />
    </div>
  )
}

export default ReporterDetailPage
