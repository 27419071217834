import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFetchCountires, useFetchCountryStateRegions, useFetchCountryStates } from '../features/countries/hooks/useFetchCountries';
import { axios } from '../config/axios';
import Button from './Button/Button';
import { toast } from 'react-toastify';

function OrderToReporter({id, refetch}) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [reporterList, setReporterList] = useState([]);

  const { countries } = useFetchCountires();
  const { states } = useFetchCountryStates(selectedCountry);
  const { regions } = useFetchCountryStateRegions(selectedState);

  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    setSelectedCountry(countryId);
    setValue("country_id", countryId);
    setSelectedState(''); // Reset state when country changes
    setValue("state_id", ''); // Reset state field
    setSelectedRegion(''); // Reset region when country changes
    setValue("region_id", ''); // Reset region field
    setReporterList([]); // Reset reporter list
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    setSelectedState(stateId);
    setValue("state_id", stateId);
    setSelectedRegion(''); // Reset region when state changes
    setValue("region_id", ''); // Reset region field
    setReporterList([]); // Reset reporter list
  };

  const handleRegionChange = (e) => {
    const regionId = e.target.value;
    setSelectedRegion(regionId);
    setValue("region_id", regionId);
  };

  useEffect(() => {
    const fetchReporterList = async () => {
      if (selectedState && selectedRegion) {
        try {
          const response = await axios.get(`/executor/admin-get-reporters?state_id=${selectedState}&region_id=${selectedRegion}`, {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
            },
          });
          setReporterList(response.data);
        } catch (error) {
          console.error("Error fetching reporter list", error);
        }
      }
    };

    fetchReporterList();
  }, [selectedState, selectedRegion]);

  const onSubmit = async (data) => {
    console.log("Submitted data:", data.reporter_id);
    console.log("Orderid:", id);

    try {
      const response = await axios.post(
        'executor/admin-assign-order',
        {
          order_id: id, // Replace with actual order_id if dynamic
          reporter_id: data.reporter_id
        },
        {
          headers: {
            'accept': '*/*',
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
            'Content-Type': 'application/json'
          }
        }
      );
      toast.success(response?.data?.data);
      console.log(response)
      refetch();
    } catch (error) {
      console.error("Error assigning order", error);
      toast.error(error.response.data.error);
    }
  };

  return (
    <div>
       <form onSubmit={handleSubmit(onSubmit)}>

        <h1 className="text-green-700 font-lg font-bold mb-2 mt-6">Asign Order to Reporter</h1>
        <div className='flex flex-wrap my-4'>


          <div className="">
            <label htmlFor="country_id" className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <select
              name="country_id"
              className="mt-1 p-1 w-full border border-gray-300 rounded-md"
              id="country_id"
              {...register("country_id", { required: "Country is required" })}
              onChange={handleCountryChange}
            >
              <option value="">Select the Country</option>
              {countries?.data.map((country) => (
                <option key={country.id} value={country.id}>{country.name}</option>
              ))}
            </select>
            <p className="text-red-500 text-sm">{errors.country_id?.message}</p>
          </div>

          <div className="ml-2">
            <label htmlFor="state_id" className="block text-sm font-medium text-gray-700">
              State
            </label>
            <select
              name="state_id"
              className="mt-1 p-1 w-full border border-gray-300 rounded-md"
              id="state_id"
              {...register("state_id", { required: "State is required" })}
              onChange={handleStateChange}
              disabled={!selectedCountry}
            >
              <option value="">Select the State</option>
              {states?.data.map((state) => (
                <option key={state.id} value={state.id}>{state.name}</option>
              ))}
            </select>
            <p className="text-red-500 text-sm">{errors.state_id?.message}</p>
          </div>

          <div className="ml-2">
            <label htmlFor="region_id" className="block text-sm font-medium text-gray-700">
              Region
            </label>
            <select
              name="region_id"
              className="mt-1 p-1 w-full border border-gray-300 rounded-md"
              id="region_id"
              {...register("region_id", { required: "Region is required" })}
              onChange={handleRegionChange}
              disabled={!selectedState}
            >
              <option value="">Select the Region</option>
              {regions?.data.map((region) => (
                <option key={region.id} value={region.id}>{region.name}</option>
              ))}
            </select>
            <p className="text-red-500 text-sm">{errors.region_id?.message}</p>
          </div>

          <div className="ml-2">
            <label htmlFor="reporter_id" className="block text-sm font-medium text-gray-700"
            onClick={()=>{console.log()}}>
              Reporter
            </label>
            <select
              name="reporter_id"
              className="mt-1 p-1 w-full border border-gray-300 rounded-md"
              id="reporter_id"
              {...register("reporter_id")}
              disabled={!selectedRegion}
            >
              <option value="">Select the Reporter</option>
              {Array.isArray(reporterList?.data) && reporterList?.data?.map((reporter) =>  (
                <option key={reporter?.id} value={reporter?.id}>{reporter?.fullname}</option>
              ))}
            </select>
            <p className="text-red-500 text-sm">{errors.reporterList?.message}</p>
          </div>

          
        </div>
        <Button type='Submit'>Asign to Reporter</Button>
      </form>
    </div>
  );
}

export default OrderToReporter;

export const getReportList = async ({ stateId, regionId }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const response = await axios.get(`/executor/admin-get-reporters?state_id=${stateId}&region_id=${regionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.data) {
    throw new Error("No data found");
  }
  return response.data;
};
