import React, { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import { Dialog } from "@headlessui/react";
import Button from "../../components/Button/Button";
import { useGetAnimal } from "../settings/AnimalTypes/hooks/useGetAnimal";
import { toast } from "react-toastify";
import { axios } from "../../config/axios";
import {
  createOrganization,
  getOrganizationList,
  getOrganizationTypeList,
} from "../../services/organization";
import { formatDate, formatDateWithoutTime } from "../../utils/formatDate";

function OrganizationPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null); // For storing selected CSV file
  const [selectedOrgType, setSelectedOrgType] = useState(""); // Store selected organization type
  let [isOpen, setIsOpen] = useState(false);
  let [isCsvOpen, setIsCsvOpen] = useState(false); // State for Add Organization (CSV) modal
  let [editModal, setEditModal] = useState(false);
  let [edititem, setEditItem] = useState({ org_type: "" });
  const [organizationTypes, setOrganizationTypes] = useState([]);

  const [event, setEvent] = useState({
    org_type: "",
    org_type_id: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let updatedData = await getOrganizationList();
    setLoading(true);
    setData(updatedData);
  };
  useEffect(() => {
    const getOrganizationTypes = async () => {
      const types = await getOrganizationTypeList();
      console.log("types");
      console.log(types?.data);
      setOrganizationTypes(types?.data);
    };
    getOrganizationTypes();
  }, []);

  const handleDelete = async (id) => {
    const token = JSON.parse(localStorage.getItem("token"));

    try {
      const response = await axios.delete(`organization/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast.success("Organization deleted successfully!");
        fetchData();
      } else {
        toast.error(
          response?.data?.message ||
            "An error occurred while deleting the organization"
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== 'text/csv') {
      toast.error("Please upload a valid CSV file.");
      setCsvFile(null);
      return;
    }
    setCsvFile(file);
  };
  

  const handleCsvUpload = async () => {
    debugger;
    if (!csvFile || !selectedOrgType) {
      toast.error("Please select an organization type and upload a CSV file.");
      return;
    }

    if (csvFile.type !== "text/csv") {
      toast.error("Invalid file format. Please upload a CSV file.");
      return;
    }
    const formData = new FormData();
    formData.append("data", csvFile);

    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const response = await axios.patch(
        `organization/upload-csv/${selectedOrgType}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.data);
        fetchData(); // Refresh the organization list after upload
      } else {
        toast.error(response?.data?.message || "Failed to upload CSV");
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "An error occurred during CSV upload"
      );
    }
  };

  if (!loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="relative overflow-x-auto mb-[200px]">
        <div className="flex justify-between mb-2">
          <button className="font-bold" onClick={() => console.log(data)}>
            Organization List
          </button>

<div>
      {/* Add Organization CSV Button */}
                   <Button
            type="button"
            className="mr-4"
            onClick={() => {
              setSelectedOrgType("");
              setCsvFile(null);
              setIsCsvOpen(true);
            }}
          >
            Add Organization (CSV)
          </Button>


          <Button
            type="button"
            onClick={() => {
              setEvent({
                org_type: "",
                org_type_id: 0,
              });
              setIsOpen(true);
            }}
          >
            Add Organization
          </Button>
          </div>
               
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">
            <tr>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Id</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Organization Name</div>
              </th>
              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">
                  Organization Type Name
                </div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Created Date</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Updated Date</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Deleted</div>
              </th>

              <th scope="col" className="px-6 py-3 text-base font-medium">
                <div className="flex gap-1 items-center">Action</div>
              </th>

              <th scope="col" className="px-2 py-3 text-base font-medium"></th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((e) => {
              return (
                <tr className="bg-white border-b ">
                  <th scope="row" className="px-6 py-4 text-sm font-medium">
                    {e?.id}
                  </th>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.org_type}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {e?.OrganizationType?.type}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.created_at)}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDate(e?.updated_at)}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium">
                    {e.is_deleted ? (
                      <Button className="bg-red-500 hover:bg-red-500 w-24">
                        Yes
                      </Button>
                    ) : (
                      <Button>No</Button>
                    )}
                  </td>

                  <td className="px-6 py-4 text-sm font-medium text-pacific-blue">
                    <Button
                      onClick={() => {
                        setEditItem(e);
                        setEditModal(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      className="px-1 py-1 text-sm font-medium bg-red-500 text-white hover:bg-red-500 ml-2"
                      onClick={() => handleDelete(e?.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {isOpen && (
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">Create Organization</span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => setIsOpen(false)}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    console.log(event);
                    try {
                      const result = await createOrganization(event);
                      console.log(result);

                      if (result?.status === 200) {
                        // Assuming 200 is the status for success
                        toast.success("Organization created successfully!");
                      } else {
                        toast.error(
                          result?.data?.message || "An error occurred"
                        );
                      }

                      fetchData();
                      setIsOpen(false);
                    } catch (error) {
                      console.error(error);
                      toast.error(error?.response?.data?.error);
                    }
                  }}
                >
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div className="">
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Organization Type
                      </label>
                      <select
                        id="org_type"
                        name="org_type_id"
                        value={event.org_type_id}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Organization Type</option>
                        {organizationTypes?.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.type}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="">
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Organization Name
                      </label>
                      <input
                        type="text"
                        id="org_type"
                        name="org_type"
                        value={event.org_type}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="flex justify-end mt-10">
                    <Button type="submit">Submit</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}

  {/* New Modal for adding Organization (CSV) */}
  {isCsvOpen && (
          <Dialog
            open={isCsvOpen}
            onClose={() => setIsCsvOpen(false)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500"
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />
              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">Upload Organization (CSV)</span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => setIsCsvOpen(false)}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleCsvUpload();
                  }}
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="">
                      <label
                        htmlFor="org_type_csv"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Organization Type
                      </label>
                      <select
                        id="org_type_csv"
                        name="org_type_id"
                        value={selectedOrgType}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={(e) => setSelectedOrgType(e.target.value)}
                        required
                      >
                        <option value="">Select Organization Type</option>
                        {organizationTypes?.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.type}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="">
                      <label
                        htmlFor="csv_upload"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Upload CSV
                      </label>
                      <input
                        type="file"
                        id="csv_upload"
                        accept=".csv"
                        onChange={handleFileChange}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        required
                      />
                    </div>
                  </div>

                  <div className="flex justify-end mt-10">
                    <Button type="submit">Upload CSV</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
        
        {editModal && (
          <Dialog
            open={editModal}
            onClose={() => setEditModal(true)}
            className="fixed inset-0 z-50 overflow-y-auto p-4 duration-500 "
          >
            <div className="flex items-center justify-center min-h-screen">
              <Dialog.Overlay className="fixed inset-0 bg-black/30" />

              <Dialog.Panel className="relative max-w-[600px] w-full mx-auto bg-white p-4 rounded">
                <Dialog.Title className="text-lg font-medium mb-6 border-b-2 flex items-center justify-between">
                  <span className="">Set Price</span>
                  <div
                    className="flex items-center justify-center "
                    onClick={() => {
                      setEditModal(false);
                    }}
                  >
                    <h1 className="bg-gray-200 px-2 rounded-md mb-1 hover:text-red-600 ">
                      x
                    </h1>
                  </div>
                </Dialog.Title>

                <form
                  onSubmit={async (e) => {
                    e.preventDefault();

                    const token = JSON.parse(localStorage.getItem("token"));

                    let data = {
                      org_type: edititem?.org_type,
                      status: edititem?.is_deleted === "true" ? true : false,
                      org_type_id: edititem.org_type_id,
                    };
                    console.log(data);
                    try {
                      const response = await axios.put(
                        `organization/${edititem?.id}`,
                        data,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      );

                      console.log(response);

                      if (response.status === 200) {
                        toast.success("Organization updated successfully!");
                        fetchData();
                        setEditModal(false);
                      } else {
                        toast.error(
                          response?.data?.message ||
                            "An error occurred while updating the organization"
                        );
                      }
                    } catch (error) {
                      console.error(error);
                      toast.error(
                        error.response?.data?.message ||
                          "An unexpected error occurred"
                      );
                    }
                  }}
                >
                  <div className="grid grid-cols-1  sm:grid-cols-2 gap-4">
                    <div className="">
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Organization Type
                      </label>
                      <select
                        id="org_type_id"
                        name="org_type_id"
                        value={edititem.org_type_id}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                        required
                      >
                        <option value="">Select Organization Type</option>
                        {organizationTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.type}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="">
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Organization Name
                      </label>
                      <input
                        type="text"
                        id="org_type"
                        name="org_type"
                        value={edititem.org_type}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                        required
                      />
                    </div>

                    <div className="">
                      <label
                        htmlFor="org_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Is Deleted
                      </label>

                      <select
                        id="status"
                        name="is_deleted"
                        value={edititem.is_deleted}
                        className="mt-1 p-1 w-full border border-gray-300 rounded-md"
                        onChange={handleEditChange}
                      >
                        <option value="">Select the Status</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex justify-end mt-10">
                    <Button type="submit">Set Organization</Button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
}

export default OrganizationPage;
