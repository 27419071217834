import React, { useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import { useGetSlaughterDataHook } from '../hooks/useGetTaskDataHook';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';
import DetailPage from './detailPage copy';
import { config } from '../../../config/config';



const NewOrder = ({ data ,refetch}) => {
  const [popup,setPopup]=useState(false)
  const [slaughter_task_id,Setslaughter_task_id]=useState(null)
  const [itemDetail,SetItemDetail]=useState({})
  console.log("Just Checking", data);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [step,SetStep]=useState(1)
  
  
  
const onSubmit = async (formData) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const data = {
    reject_reason: formData?.reject_reason,
  };

  console.log(data);

  try {
    const response = await axios.patch(
      `${config.backendURL}executor/slaughter-reject-task/${slaughter_task_id}`, 
      data, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.data) {
      throw new Error("No data found");
    }

    setPopup(false);
    toast.success("Rejected Successfully");
    refetch()
    return response.data;
  } catch (error) {
    console.error("Error posting:", error);
    toast.error("An error occurred while rejecting.");
    throw error;
  }
};

const acceptFunction = async (id) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const data = {
    status: "ONGOING",
  };

  console.log(data);

  try {
    const response = await axios.patch(
      `${config.backendURL}executor/slaughter-change-task-status/${id}`, 
      data, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.data) {
      throw new Error("No data found");
    }
    refetch()
    toast.success("Accept Successfully");
   
    return response.data;
  } catch (error) {
    console.error("Error posting:", error);
    toast.error("An error occurred while rejecting.");
    throw error;
  }
};
  

  return (
    <>
     {step==1?
      <div className='min-h-[100vh]'>

        {popup&&
        <div className="absolute z-10 right-[30%] flex flex-col items-center min-h-screen px-3 md:mt-24 mt-10 mb-[200px] md:mb-56 ">
          <div className="flex flex-col items-center border border-quill-grey rounded-xl bg-white md:w-[503px] w-full p-4 shadow shadow-black">
            <span className="md:text-2xl text-lg text-pacific-blue">Please enter your rejected reason</span>
            <span className="md:text-2xl text-lg text-pacific-blue absolute right-5 top-0 cursor-pointer hover:text-red-500" 
            onClick={()=>setPopup(false)}>x</span>
            <form onSubmit={handleSubmit(onSubmit)} className='bg-[#EBEBEB] rounded-lg w-[95%] p-4 mt-5 '>
              <div className='flex w-full justify-between pb-3'>
               
              </div>

              <div className='flex w-full justify-between pb-3'>
                <label htmlFor="reject_reason" className='ont-medium text-[#525252]'>
                  Slaughter Name
                </label>
                <select
                  id="reject_reason"
                  name="reject_reason"
                  required
                  {...register('reject_reason', { required: true })}
                  className=' font-medium text-pacific-blue'
                >
                   <option value="">Select reason</option>
                   <option value="Unable to attend">Unable to attend</option>
                   <option value="To Many Tasks">To Many Tasks</option>
                   <option value="Health Issues">Health Issues</option>
                   <option value="Ohters">Others</option>
                </select>
                {errors.reject_reason && <span className='text-red-500'>This field is required</span>}
              </div>

              
              <button type="submit" className='mt-4 bg-red-500 text-white rounded-lg px-4 py-2'>
                Rejected
              </button>
            </form>
          </div>
        </div>
}




          <div className="relative overflow-x-auto mb-[200px]">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2] ">

                <tr>


                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    <div className='flex gap-1 items-center'>
                    Order ID


                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    <div className='flex gap-1 items-center'>
                      Task Date


                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    <div className='flex gap-1 items-center'>

                      Reporter  Name

                    </div>

                  </th>
                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    Payment Status
                  </th>

                  
                  <th scope="col" className="px-6 py-3 text-base font-medium">
                    Action
                  </th>
                  <th scope="col" className="px-2 py-3 text-base font-medium">

                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((e, index) => (
                  <tr key={index} className="bg-white border-b ">
                    <th scope="row" className="px-6 py-4 text-sm font-medium">
                      {e?.reporter_order?.order?.id}
                    </th>
                    <td className="px-6 py-4 text-sm font-medium">
                      {e?.reporter_order?.created_at}
                    </td>
                    
                    <td className="px-6 py-4 text-sm font-medium">
                      {e?.reporter_order?.reporter?.fullname
                      }
                    </td>
                    <td className="px-6 py-4 text-[#09CB3F] text-sm font-medium">
                      {e?.reporter_order?.order?.payment_status}
                    </td>
                    
                   

                    <td className="px-6 py-4 text-sm font-medium flex">
                      <button className="mr-2 flex gap-1 cursor-pointer px-5 pl-2 text-[#09CB3F] border border-[#09CB3F] rounded-2xl text-center py-2"
                      onClick={()=>{
                        acceptFunction(e?.id)
                      }}
                      >
                        <IoArrowDownCircleOutline className="text-lg" />
                        <p> Accept</p>
                      </button>

                      <button className="flex gap-1 cursor-pointer px-5 pl-2 border border-red-500 text-red-500 rounded-2xl text-center py-2"
                      onClick={()=>{
                        Setslaughter_task_id(e?.id)
                        setPopup(true)
                      }}
                      >
                        <IoArrowDownCircleOutline className="text-lg" />
                        <p> Reject</p>
                      </button>
                    </td>
                    <th scope="row" className="px-2 py-4" >
                      <button onClick={()=>{
                        SetItemDetail(e)
                        SetStep(2)
                      }}> <IoIosArrowForward /></button>
                    </th>
                  </tr>
                ))}
              </tbody>

            </table>


          </div>
        </div>
        :
        <DetailPage
         changeDetailPage={()=>{SetStep(1)}} 
         activeTab="New"
         ItemDetail={itemDetail}

        />
            }
      </>
      )
    }

      export default NewOrder