import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import { useGetSlaughterDataHook } from "../hooks/useGetExecutorDataHook";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { config } from "../../../config/config";
import { formatDate, formatDateWithoutTime } from "../../../utils/formatDate";
import DetailPage from "../../task new/components/detailPage copy";
import ReporterDetailPage from "../../task new/components/reporterDetailPage";
import { Tab } from "@headlessui/react";


const NewOrder = ({ data ,tab, refetch}) => {
  console.log("data");
  console.log(data);

  const { slaughterList, isError, isLoading } = useGetSlaughterDataHook();
  const [popup, setPopup] = useState(false);
  const [step,SetStep]=useState(1)
  const [itemDetail,SetItemDetail]=useState({})
  const [reporter_order_id, setreporter_order_id] = useState(null);
  console.log("Just Checking", data);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    const token = JSON.parse(localStorage.getItem("token"));
    let data = {
      slaughter_id: JSON.parse(formData?.slaughter_id),
      reporter_order_id,
    };
    console.log(data);

    try {
      const response = await axios.post(
        `${config.backendURL}executor/reporter-assign-task`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.data) {
        throw new Error("No data found");
      }
      setPopup(false);
      refetch()

      toast.success("Assign the order to Slaughter Succesfull");

      return response.data;
    } catch (error) {
      console.error("Error posting slaughter data:", error.response.data.error);
      toast.error(error.response.data.error);
      throw error;
    }
  };

  
  const changeStatus = async (id) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const data = {
      status: "COMPLETED",
    };

    console.log(data);

    try {
      const response = await axios.patch(
        `${config.backendURL}executor/slaughter-change-task-status/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.data) {
        throw new Error("No data found");
      }
      refetch()
      console.log(response)
      toast.success(response?.data?.data);

      return response.data;
    } catch (error) {
      console.error("Error posting:", error);
      toast.error("An error occurred while rejecting.");
      throw error;
    }
  };

  const handleClose = () => {
    setPopup(false);
  };

  return (
    <>
     {step==1?
      <div className="min-h-[100vh]">
        {popup && (
          <div className="absolute z-10 right-[30%] flex flex-col items-center min-h-screen px-3 md:mt-24 mt-10 mb-[200px] md:mb-56">
            <div className="flex flex-col items-center border border-quill-grey rounded-xl shadow-lg bg-white md:w-[503px] w-full p-6">
            <div className="flex w-full justify-end">
            <button
              onClick={handleClose}
              className="absolute top-3 right-6 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600 transition duration-200"
            >
              &times;
            </button>
            </div>
              <span className="md:text-2xl text-lg font-semibold text-gray-800 mb-4">Assign order to Slaughter</span>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="bg-gray-100 rounded-lg w-full p-4"
              >
                <div className="flex w-full justify-between  pb-3"></div>

                <div className="flex w-full justify-between  items-center pb-3">
                  <label
                    htmlFor="slaughter_id"
                    className="md:text-sm text-xs font-medium text-gray-700"
                  >
                    Slaughter Name
                  </label>
                  <select
                    id="slaughter_id"
                    name="slaughter_id"
                    {...register("slaughter_id", { required: true })}
                    className="w-full md:text-sm text-xs font-medium text-gray-800 bg-white border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    defaultValue="Maybank Berhad"
                  >
                    {slaughterList?.data.map((e) => {
                      return <option value={e.id}>{e.fullname}</option>;
                    })}
                  </select>
                  {errors.slaughter_id && (
                    <span className="text-red-500 text-xs">This field is required</span>
                  )}
                </div>

                <button
                  type="submit"
                  className="mt-4 w-full text-white font-medium rounded-lg px-4 py-2 transition duration-200"
        style={{ backgroundColor: 'rgb(0 173 185 / var(--tw-bg-opacity))' }} >
                  Assign
                </button>
              </form>
            </div>
          </div>
        )}

        <div className="relative overflow-x-auto mb-[200px]">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-pacific-blue uppercase bg-[#CFF0F2]">
              <tr>
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">ID</div>
                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Order ID</div>
                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  <div className="flex gap-1 items-center">Portions</div>
                </th>
              
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  NO OF Participants
                </th>
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  Slaughter Assignment
                </th>
                  {tab !=="new" &&
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  Slaughter Name
                </th>
}
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  Task Date
                </th>
                {(tab==="new" || tab==="PARTIAL_COMPLETED") &&
                <th scope="col" className="px-6 py-3 text-base font-medium">
                  Action
                </th>
}
                <th
                  scope="col"
                  className="px-2 py-3 text-base font-medium"
                ></th>
              </tr>
            </thead>
            
            <tbody>
  {tab === "new" ? (
  
  data?.modifiedassignedOrders?.map((e, index) => (
    !e?.slaughter_assigned && (
      <tr key={index} className="bg-white border-b">
        <th scope="row" className="px-6 py-4 text-sm font-medium">{e?.id}</th>
        <td className="px-6 py-4 text-sm font-medium">{e?.order_id}</td>
        <td className="px-6 py-4 text-sm font-medium">{e?.order?.total_portions}</td>
        <td className="px-6 py-4">
          {(() => {
            let totalParticipants = 0;
            if (e?.order?.principle_portions > 0) totalParticipants += 1;
            if (e?.order?.OrderHistoryOrganization) totalParticipants += e.order.OrderHistoryOrganization.length;
            if (e?.order?.familyMembersPortions) totalParticipants += e.order.familyMembersPortions.length;
            return totalParticipants;
          })()}
        </td>
        <td className="px-6 py-4">{e?.slaughter_assigned ? "true" : "false"}</td>
        <td className="px-6 py-4">{formatDate(e.created_at)}</td>
        <td className="px-6 py-4 text-sm font-medium">
          {e?.slaughter_assigned ? (
            <button className="flex gap-1 cursor-not-allowed px-5 pl-2 text-gray-500 border border-gray-500 rounded-2xl text-center py-2" disabled>
              <IoArrowDownCircleOutline className="text-lg" />
              <p> Assign</p>
            </button>
          ) : (
            <button className="flex gap-1 cursor-pointer px-5 pl-2 text-[#09CB3F] border border-[#09CB3F] rounded-2xl text-center py-2" onClick={() => {
              setreporter_order_id(e?.id);
              setPopup(true);
            }}>
              <IoArrowDownCircleOutline className="text-lg" />
              <p> Assign</p>
            </button>
          )}
        </td>
        <th scope="row" className="px-2 py-4">
          <IoIosArrowForward onClick={() => {
            SetItemDetail(e);
            SetStep(2);
          }} />
        </th>
      </tr>
    )
  ))
  ) : tab === "asign" ? (
    data?.modifiedassignedOrders.map((e, index) => {
    
      const hasPartialCompletedTask = e?.SlaughterTasks?.some(
        (task) => task.status !== "PARTIAL_COMPLETED" && task.status !== "COMPLETED"
      );
    
      // Render table row only if the condition is met
      if (hasPartialCompletedTask) {
        return (
          <tr key={index} className="bg-white border-b">
            <th scope="row" className="px-6 py-4 text-sm font-medium">{e?.id}</th>
            <td className="px-6 py-4 text-sm font-medium">{e?.order_id}</td>
            <td className="px-6 py-4 text-sm font-medium">{e?.order?.total_portions}</td>
            <td className="px-6 py-4">
              {(() => {
                let totalParticipants = 0;
                if (e?.order?.principle_portions > 0) totalParticipants += 1;
                if (e?.order?.OrderHistoryOrganization) totalParticipants += e.order.OrderHistoryOrganization.length;
                if (e?.order?.familyMembersPortions) totalParticipants += e.order.familyMembersPortions.length;
                return totalParticipants;
              })()}
            </td>
            <td className="px-6 py-4">{e?.slaughter_assigned ? "true" : "false"}</td>
            {tab !== "new" && (
  <td className="px-6 py-4">
    {e.SlaughterTasks
      .filter(task => task.status !== "REJECTED")
      .map(slaughter => slaughter.slaughter.fullname)
      .join(", ")}
  </td>
)}

            <td className="px-6 py-4">{formatDate(e.created_at)}</td>
           
            <th scope="row" className="px-2 py-4">
              <IoIosArrowForward
                onClick={() => {
                  SetItemDetail(e);
                  SetStep(2);
                }}
              />
            </th>
          </tr>
        );
      }
    
      // Return null if the condition is not met to avoid rendering a row
      return null;
    })
  ) 
  
  : tab === "PARTIAL_COMPLETED" ? (
    data?.modifiedassignedOrders.map((e, index) => {
      // Check if any SlaughterTasks have status "PARTIAL_COMPLETED"
      const hasPartialCompletedTask = e?.SlaughterTasks?.some(
        (task) => task.status === "PARTIAL_COMPLETED"
      );
    
      // Render table row only if the condition is met
      if (hasPartialCompletedTask) {
        return (
          <tr key={index} className="bg-white border-b">
            <th scope="row" className="px-6 py-4 text-sm font-medium">{e?.id}</th>
            <td className="px-6 py-4 text-sm font-medium">{e?.order_id}</td>
            <td className="px-6 py-4 text-sm font-medium">{e?.order?.total_portions}</td>
            <td className="px-6 py-4">
              {(() => {
                let totalParticipants = 0;
                if (e?.order?.principle_portions > 0) totalParticipants += 1;
                if (e?.order?.OrderHistoryOrganization) totalParticipants += e.order.OrderHistoryOrganization.length;
                if (e?.order?.familyMembersPortions) totalParticipants += e.order.familyMembersPortions.length;
                return totalParticipants;
              })()}
            </td>
            <td className="px-6 py-4">{e?.slaughter_assigned ? "true" : "false"}</td>
            {tab !== "new" && (
  <td className="px-6 py-4">
    {e.SlaughterTasks
      .filter(task => task.status !== "REJECTED")
      .map(slaughter => slaughter.slaughter.fullname)
      .join(", ")}
  </td>
)}

            <td className="px-6 py-4">{formatDate(e.created_at)}</td>
            <td className="px-6 py-4 text-sm font-medium">
            <button className="mr-2 flex gap-1 cursor-pointer px-5 pl-2 text-green-500 border border-green-500 rounded-2xl text-center py-2"
                          onClick={() => {
                              // Find the task with status 'PARTIAL_COMPLETED'
    const task = e?.SlaughterTasks?.find(task => task.status === 'PARTIAL_COMPLETED');

    if (task) {
        // Call changeStatus with the id of the found task
        changeStatus(task?.id);
        console.log(task);  // Log the task object for debugging
    } else {
        console.log("No task found with status 'PARTIAL_COMPLETED'");
    }
                          }}
                        >
                          <IoArrowDownCircleOutline className="text-lg" />
                          <p> Completed</p>
                        </button>
            </td>
            <th scope="row" className="px-2 py-4">
              <IoIosArrowForward
                onClick={() => {
                  SetItemDetail(e);
                  SetStep(2);
                }}
              />
            </th>
          </tr>
        );
      }
    
      // Return null if the condition is not met to avoid rendering a row
      return null;
    })
    
  )
  :tab === "COMPLETED" ?
  (
    data?.modifiedassignedOrders.map((e, index) => {
      // Check if any SlaughterTasks have status "PARTIAL_COMPLETED"
      const hasPartialCompletedTask = e?.SlaughterTasks?.some(
        (task) => task.status === "COMPLETED"
      );
    
      // Render table row only if the condition is met
      if (hasPartialCompletedTask) {
        return (
          <tr key={index} className="bg-white border-b">
            <th scope="row" className="px-6 py-4 text-sm font-medium">{e?.id}</th>
            <td className="px-6 py-4 text-sm font-medium">{e?.order_id}</td>
            <td className="px-6 py-4 text-sm font-medium">{e?.order?.total_portions}</td>
            <td className="px-6 py-4">
              {(() => {
                let totalParticipants = 0;
                if (e?.order?.principle_portions > 0) totalParticipants += 1;
                if (e?.order?.OrderHistoryOrganization) totalParticipants += e.order.OrderHistoryOrganization.length;
                if (e?.order?.familyMembersPortions) totalParticipants += e.order.familyMembersPortions.length;
                return totalParticipants;
              })()}
            </td>
            <td className="px-6 py-4">{e?.slaughter_assigned ? "true" : "false"}</td>
            {tab !== "new" && (
  <td className="px-6 py-4">
    {e.SlaughterTasks
      .filter(task => task.status !== "REJECTED")
      .map(slaughter => slaughter.slaughter.fullname)
      .join(", ")}
  </td>
)}

            <td className="px-6 py-4">{formatDate(e.created_at)}</td>
           
            <th scope="row" className="px-2 py-4">
              <IoIosArrowForward
                onClick={() => {
                  SetItemDetail(e);
                  SetStep(2);
                }}
              />
            </th>
          </tr>
        );
      }
    
      // Return null if the condition is not met to avoid rendering a row
      return null;
    })
  )
  :null
  }
</tbody>

          </table>
        </div>
      </div>

:
<ReporterDetailPage
 changeDetailPage={()=>{SetStep(1)}} 
 activeTab={tab}
 ItemDetail={itemDetail}

/>
    }
    </>
  );
};

export default NewOrder;
